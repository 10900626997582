import { Account } from "../../../../types";
import { PencilIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import { RefreshIcon } from "@heroicons/react/solid";
interface IAccountDetailsProps {
  data?: Account;
  setIsEditing: (isEditing: boolean) => void;
  loading: boolean;
}

const AccountDetails: React.FC<IAccountDetailsProps> = ({
  data,
  setIsEditing,
  loading,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {t("home.account.account_info")}{" "}
          {loading && (
            <RefreshIcon
              className="inline-block animate-spin h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          )}
        </h3>
      </div>
      <div className="card">
        <div className="card-header-container">
          <div className="card-header-items">
            <div className="flex items-center">
              <div className="ml-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {data?.name}
                </h3>
              </div>
            </div>
          </div>
        </div>
        <dl className="divide-y divide-gray-200">
          <div className="card-content-row">
            <dt className="card-content-row-title">
              {t("home.account.status")}
            </dt>
            <dd className="card-content-row-data">
              <span className="flex-grow">
                {data?.active === "1" ? (
                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 border border-gray-300">
                    {t("home.account.active")}
                  </span>
                ) : (
                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800 border border-gray-300">
                    {t("home.account.inactive")}
                  </span>
                )}
              </span>
            </dd>
          </div>
          <div className="card-content-row">
            <dt className="card-content-row-title">
              {t("home.account.accountName")}
            </dt>
            <dd className="card-content-row-data">
              <span className="flex-grow">{data?.name}</span>
            </dd>
            <dt className="card-content-row-title">
              {t("home.account.address")}
            </dt>
            <dd className="card-content-row-data">
              <span className="flex-grow">{data?.address}</span>
            </dd>
          </div>
          <div className="card-content-row">
            <dt className="card-content-row-title">
              {t("home.account.contactName")}
            </dt>
            <dd className="card-content-row-data">
              <span className="flex-grow">{data?.primary_contact_name}</span>
            </dd>
            <dt className="card-content-row-title">
              {t("home.account.contactPhone")}
            </dt>
            <dd className="card-content-row-data">
              <span className="flex-grow">{data?.primary_contact_phone}</span>
            </dd>
            <dt className="card-content-row-title">
              {t("home.account.contactEmail")}
            </dt>
            <dd className="card-content-row-data">
              <span className="flex-grow">{data?.email}</span>
            </dd>
          </div>
          <div className="card-content-row">
            <dt className="card-content-row-title">
              {t("home.account.pinkoodi")}
            </dt>
            <dd className="card-content-row-data">
              <span className="flex-grow">{data?.notes.split("|")[0]}</span>
            </dd>
            <dt className="card-content-row-title">
              {t("home.account.notes")}
            </dt>
            <dd className="card-content-row-data">
              <span className="flex-grow">{data?.notes.split("|")[1]}</span>
              <span className="ml-4 flex-shrink-0">
                <button
                  className="relative inline-flex items-center px-3 py-2 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                  onClick={() => setIsEditing(true)}
                >
                  <PencilIcon
                    className="-ml-1 -mr-1 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </button>
              </span>
            </dd>
          </div>
        </dl>
      </div>
    </>
  );
};

export default AccountDetails;
