import { Dialog, Transition } from "@headlessui/react";
import * as React from "react";
import { Fragment, useState } from "react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Navigation } from "../../types";
import SideNav from "./SideNav";

type Props = {
  navigation: Navigation[];
  userName: string;
  signOut: () => void;
};

const Nav: React.FC<Props> = ({ signOut, navigation, userName, children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const currentPage = React.useMemo(() => navigation.find((e) => e.current), [
    navigation,
  ]);

  const onPageSelected = (page: Navigation) => {
    setSidebarOpen(false);
  };

  return (
    <div className="h-screen flex flex-1 overflow-hidden bg-white">
      <div className="hidden lg:flex lg:flex-shrink-0">
        <div className="flex flex-col w-64 border-r pt-5 pb-4 bg-black">
          <SideNav
            navigation={navigation}
            userName={userName}
            onPageChange={onPageSelected}
            signOut={signOut}
          />
        </div>
      </div>
      <main className="main">
        <div className="top-bar">
          <button
            className="px-4 border-gray-200 text-gray-500 focus:outline-none focus:none focus:ring-inset focus:ring-primary lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
          <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog
              as="div"
              static
              className="fixed inset-0 flex z-40 lg:hidden"
              open={sidebarOpen}
              onClose={setSidebarOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
              </Transition.Child>
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <div className="relative flex flex-col w-64 pt-5 pb-4 bg-black">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-none focus:ring-inset focus:ring-none"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <SideNav
                    navigation={navigation}
                    userName={userName}
                    onPageChange={onPageSelected}
                    signOut={signOut}
                  />
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 w-14" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </Dialog>
          </Transition.Root>
          <div className="top-bar-title">
            <h1 className="top-bar-title-h1">{currentPage?.name}</h1>
          </div>
        </div>
        <div className="content">{children}</div>
      </main>
    </div>
  );
};

export default Nav;
