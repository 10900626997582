import React from "react";

const LogoHeader = () => {
  return (
    <div>
      <div className="text-center text-5xl font-extrabold text-black">
        <img
          className="max-h-40 w-auto ml-auto mr-auto block"
          src={"/images/logo-taksitampere.png"}
          alt="Taksi Tampere logo"
        />
      </div>
    </div>
  );
};

export default LogoHeader;
