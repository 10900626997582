import { RouteProps } from "react-router";

/* Helper to prevent all methods of rendering in a Route.
 * https://reactrouter.com/web/api/Route/route-render-methods
 *
 * This is useful when you need to completely modify how a route renders,
 * while retaining other props like "path" and "exact".
 */
export const eliminateRenderMethods = (props: RouteProps) => ({
  location: props.location,
  path: props.path,
  exact: props.exact,
  sensitive: props.sensitive,
  strict: props.strict,
  // Eliminated props:
  // component: props.component,
  // render: props.render,
  // children: props.children,
});
