import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { AuthStatus, ICurrent } from "../../types";
import { eliminateRenderMethods } from "../../common/util/routes";

interface IProps extends RouteProps {
  authentication: AuthStatus;
}

const AppRoute = ({ authentication, ...props }: IProps) => {
  if (authentication === AuthStatus.SIGNED_IN) {
    return <Route {...props} />;
  }
  return (
    <Route {...eliminateRenderMethods(props)}>
      <Redirect to="/login" />;
    </Route>
  );
};

const mapStateToProps = (state: ICurrent) => ({
  authentication: state.authentication,
});

export default connect(mapStateToProps)(AppRoute);
