import React from "react";
import Amplify from "aws-amplify";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import { UserPoolAuth } from "../types";
import Loading from "../common/components/pages/Loading";
import NavAdmin from "./components/NavAdmin";
import { AmplifyAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";
import { Route, Switch } from "react-router";
import Applications from "./components/pages/applications/ApplicationsPage";
import ApprovedAccounts from "./components/pages/approved/ApprovedPage";
import ApplicationDetails from "./components/pages/details/ApplicationDetailsPage";
import { useTranslation } from "react-i18next";

const fetchUserPoolDetails = async () => {
  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set("X-API-KEY", "A9tRy6XE4so5FKruvbEoTUVZUZHPKKdj");
  const url = "/api/userPoolDetails";
  return fetch(url, {
    method: "GET",
    headers: requestHeaders,
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(String(response.status));
      } else {
        return response.json();
      }
    })
    .catch((e) => console.log(e));
};

const AppAdmin = () => {
  const { t } = useTranslation();
  const [cognitoState, setCognitoState] = React.useState(false);
  const [signedIn, setSignedIn] = React.useState(false);
  React.useEffect(() => {
    fetchUserPoolDetails().then((response) => {
      const poolDetails: UserPoolAuth = response;

      Amplify.configure({
        Auth: {
          mandatorySignIn: poolDetails?.Auth?.mandatorySignIn,
          region: poolDetails?.Auth?.region,
          userPoolId: poolDetails?.Auth?.userPoolId,
          userPoolWebClientId: poolDetails?.Auth?.userPoolWebClientId,
        },
      });
      setCognitoState(true);
    });
  });

  React.useEffect(() => {
    onAuthUIStateChange((nextAuthState, authData) => {
      console.log("onAuthUIStateChange", nextAuthState);
      if (nextAuthState === AuthState.SignedIn) {
        setSignedIn(true);
      } else {
        setSignedIn(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!cognitoState) {
    return <Loading />;
  }
  return signedIn ? (
    <NavAdmin>
      <Switch>
        <Route exact path="/admin/" component={Applications} />
        <Route exact path="/admin/home" component={Applications} />
        <Route exact path="/admin/approved" component={ApprovedAccounts} />
        <Route exact path="/admin/a/:id" component={ApplicationDetails} />
        <Route>{t("notfound.paragraph")}</Route>
      </Switch>
    </NavAdmin>
  ) : (
    <AmplifyAuthenticator usernameAlias="email">
      <AmplifySignIn hideSignUp={true} slot="sign-in" />
    </AmplifyAuthenticator>
  );
};

export default AppAdmin;
