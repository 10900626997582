import { Auth } from "aws-amplify";
import { useMutation } from "react-query";
const url = "/api/application";

const fetcher = async (id: string, action: string, message: string) => {
  const requestHeaders: HeadersInit = new Headers();
  const session = await Auth.currentSession();
  const token = session.getAccessToken().getJwtToken();
  requestHeaders.set("Authorization", `Bearer ${token}`);

  return fetch(`${url}/${id}/${action}`, {
    method: "POST",
    headers: requestHeaders,
    body:
      message === ""
        ? JSON.stringify({})
        : JSON.stringify({ message: message }),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(String(response.status));
      } else {
        return response;
      }
    })
    .catch((e) => console.log(e));
};

export interface RejectProps {
  id: string;
  message: string;
}

const useReject = () => {
  const mutation = useMutation((props: RejectProps) =>
    fetcher(props.id, "reject", props.message)
  );
  return mutation;
};

export default useReject;
