export function authHeader() {
  // return authorization header with basic auth credentials
  let userkey = JSON.parse(localStorage.getItem("user-key"));

  if (userkey) {
    return "Basic " + userkey;
  } else {
    return "";
  }
}

export function userAccountdId() {
  // return authorization header with basic auth credentials
  let user = JSON.parse(localStorage.getItem("user-info") || "{}");

  if (user.accountId != null) {
    return user.accountId;
  }
}
