import React from "react";
import { useTranslation } from "react-i18next";
import {
  AccountApplication,
  BillingInformation,
  BillingInformationEinvoicing,
  BillingInformationEmail,
  BillingInformationPost,
} from "../../../../types";
interface IProps {
  data: AccountApplication | undefined;
  loading: boolean;
  onDecline: () => void;
  onAccept: () => void;
}

const DetailsCard: React.FC<IProps> = ({
  data,
  loading,
  onDecline,
  onAccept,
}) => {
  const account = data;
  const { t } = useTranslation();

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {t("home.applications.application")}
          </h3>
          <h3 className="text-lg leading-6 font-light text-gray-900">
            {account?.name}
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            {new Date(
              account !== undefined ? account.createdAt : ""
            ).toLocaleString()}
          </p>
        </div>
        <div className="mt-5 border-t border-gray-200">
          <dl className="sm:divide-y sm:divide-gray-200">
            <DetailRow
              label={t("home.applications.companyName")}
              content={account?.name}
            />
            <DetailRow
              label={t("home.applications.businessID")}
              content={account?.businessId}
            />
            <DetailRow
              label={t("home.applications.nickname")}
              content={account?.nickname}
            />
            <MultiRow
              labels={[
                t("home.applications.contactName"),
                t("home.applications.contactEmail"),
                t("home.applications.contactPhone"),
              ]}
              //@ts-ignore
              content={[
                account?.contactPerson.name,
                account?.contactPerson.email,
                account?.contactPerson.phone,
              ]}
            />
            <BillingTypeRow billingInfo={account?.billingInformation} />
            <div className="py-12 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">
                {t("home.applications.actions_title")}
              </dt>

              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-black text-base font-medium text-white hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black sm:ml-3 sm:mt-2 sm:w-auto sm:text-sm"
                onClick={onDecline}
                disabled={loading}
              >
                {t("home.applications.decline_button")}
              </button>
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-black hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:ml-3 sm:mt-2 sm:w-auto sm:text-sm"
                onClick={onAccept}
                disabled={loading}
              >
                {t("home.applications.accept_button")}
              </button>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};

interface IDetailRowProps {
  label: string;
  content: string | undefined;
}

const DetailRow = ({ label, content }: IDetailRowProps) => {
  return (
    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
      <dt className="text-sm font-medium text-gray-500">{label}</dt>
      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        {content}
      </dd>
    </div>
  );
};

interface IMultiRowProps {
  labels: string[];
  content: [];
}

const MultiRow = ({ labels, content }: IMultiRowProps) => {
  return (
    <div className="py-4 sm:py-4">
      {content.map((value, i) => {
        return (
          <div
            key={`contactPerson-${i}`}
            className="py-1 sm:py-1 sm:grid sm:grid-cols-3 sm:gap-4"
          >
            <dt className="text-sm font-medium text-gray-500">
              {i < labels.length ? labels[i] : ""}
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {value}
            </dd>
          </div>
        );
      })}
    </div>
  );
};

interface IBillingTypeRowProps {
  billingInfo: BillingInformation | undefined;
}

const BillingTypeRow = ({ billingInfo }: IBillingTypeRowProps) => {
  const { t } = useTranslation();

  const renderBillingType = (): JSX.Element => {
    switch (billingInfo?.type) {
      case "email":
        return renderEmailBilling(billingInfo);
      case "einvoicing":
        return renderEInvoiceBilling(billingInfo);
      case "post":
        return renderPostBilling(billingInfo);
      case "cash":
        return renderCashBilling();
      case undefined:
        return <div />;
    }
  };

  const renderEmailBilling = (billingInfo: BillingInformationEmail) => {
    return (
      <div>
        <dd className="mt-1 text-xs text-gray-500 sm:mt-0 sm:col-span-2">
          {t("home.applications.billingEmail")}
        </dd>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {billingInfo.email}
        </dd>
      </div>
    );
  };

  const renderEInvoiceBilling = (billingInfo: BillingInformationEinvoicing) => {
    return (
      <div>
        <dd className="mt-1 text-xs text-gray-500 sm:mt-0 sm:col-span-2">
          {t("home.applications.billingEinvoice")}
        </dd>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {billingInfo.operator}
        </dd>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {billingInfo.address}
        </dd>
      </div>
    );
  };

  const renderPostBilling = (billingInfo: BillingInformationPost) => {
    return (
      <div>
        <dd className="mt-1 text-xs text-gray-500 sm:mt-0 sm:col-span-2">
          {t("home.applications.billingPost")}
        </dd>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {billingInfo.postalAddress.streetAddress}
        </dd>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {billingInfo.postalAddress.postalCode}
        </dd>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {billingInfo.postalAddress.city}
        </dd>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {billingInfo.postalAddress.country}
        </dd>
      </div>
    );
  };

  const renderCashBilling = () => {
    return (
      <div>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {t("home.applications.billingCash")}
        </dd>
      </div>
    );
  };

  return (
    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
      <dt className="text-sm font-medium text-gray-500">
        {t("home.applications.type")}
      </dt>
      {renderBillingType()}
    </div>
  );
};

export default DetailsCard;
