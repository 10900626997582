import { authHeader, userAccountdId } from "./auth-header";

const url = "/api/account";
const url2 = "user";

/*
{
  "phone": "string"
}
*/

const fetcher = async (userAccountId: string, phone: string) => {
  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set("Authorization", authHeader());

  return fetch(`${url}/${userAccountId}/${url2}`, {
    method: "DELETE",
    headers: requestHeaders,
    body: phone,
  });
};

const UseDeleteUser = (phone: string) => {
  return fetcher(userAccountdId(), phone);
};

export default UseDeleteUser;
