import Papa from "papaparse";
import { ApprovedAccount } from "../../types";

interface ICsv {
  httpmethod: string;
  org_id: string;
  emptyCol: string;
  company_name: string;
  parent_organization: string;
  parent_org_id: string;
  addr_street1: string;
  addr_street2: string;
  addr_zipcode: string;
  addr_city: string;
  business_id: string;
  contact_person: string;
  custtype: string;
  contact_phone_number: string;
  contact_email_address: string;
  country: string;
  contact_note: string;
  external_customer_id: string;
  external_customer_id2: string;
  sales_group: string;
  invoice_language: string;
  eBillable: string;
  linked_organization: string;
  costcentre: string;
  eBilling_Address: string;
  eBilling_Operator: string;
  billing_reference: string;
}

const headers = [
  "httpmethod",
  "org_id",
  "",
  "company_name",
  "parent_organization",
  "parent_org_id",
  "addr_street1",
  "addr_street2",
  "addr_zipcode",
  "addr_city",
  "business_id",
  "contact_person",
  "custtype",
  "contact_phone_number",
  "contact_email_address",
  "country",
  "contact_note",
  "external_customer_id",
  "external_customer_id2",
  "sales_group",
  "invoice_language",
  "eBillable",
  "linked_organization",
  "costcentre",
  "eBilling_Address",
  "eBilling_Operator",
  "billing_reference",
];

const papaConfig: Papa.UnparseConfig = {
  //quotes: false, //or array of booleans
  quoteChar: '"',
  escapeChar: '"',
  delimiter: ";",
  header: true,
  newline: "\r\n",
  skipEmptyLines: false, //other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
  columns: headers, //or array of strings
};

const generateCsv = (data?: ApprovedAccount[]) => {
  let billingType: string = "";

  const convertedCsv: ICsv[] = data!.map((user: ApprovedAccount) => {
    if (user.billingInformation.type === "einvoicing") {
      billingType = "1";
    } else if (user.billingInformation.type === "email") {
      billingType = "4";
    } else if (user.billingInformation.type === "post") {
      billingType = "0";
    } else if (user.billingInformation.type === "cash") {
      billingType = "3";
    }

    let row = {
      httpmethod: "POST",
      org_id: "",
      emptyCol: "",
      company_name: user.name,
      parent_organization: "",
      parent_org_id: "",
      addr_street1: user.billingInformation.postalAddress.streetAddress,
      addr_street2: "",
      addr_zipcode: user.billingInformation.postalAddress.postalCode,
      addr_city: user.billingInformation.postalAddress.city,
      business_id: user.businessId,
      contact_person: user.contactPerson.name,
      custtype: "B",
      contact_phone_number: user.contactPerson.phone.replace("+", "00"),
      contact_email_address: user.contactPerson.email,
      country: "FIN",
      contact_note: "",
      external_customer_id: "",
      external_customer_id2: user.iCabbiAccount.identifier,
      sales_group: "",
      invoice_language: "1",
      eBillable: billingType,
      linked_organization: "",
      costcentre: "",
      eBilling_Address:
        user.billingInformation.type === "einvoicing"
          ? user.billingInformation.address
          : "",
      eBilling_Operator:
        user.billingInformation.type === "einvoicing"
          ? user.billingInformation.operator
          : "",
      billing_reference: "",
    };

    return row;
  });
  return Papa.unparse(convertedCsv, papaConfig);
};

export default generateCsv;
