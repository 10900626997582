import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import history from "./history";
import Loading from "./common/components/pages/Loading";
import AppAdmin from "./app-admin/AppAdmin";

import AppUser from "./app-user/AppUser";

const App = () => {
  return (
    <React.Suspense fallback={<Loading />}>
      <Router history={history}>
        <Switch>
          <Route path={["/admin", "/admin/*"]}>
            <AppAdmin />
          </Route>
          <Route>
            <AppUser />
          </Route>
        </Switch>
      </Router>
    </React.Suspense>
  );
};

export default App;
