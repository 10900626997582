import React from "react";
import { DownloadIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import generateCsv from "../../../../common/util/csvGenerator";
import { ApprovedAccount } from "../../../../types";

interface ICsvButtonProps {
  data?: ApprovedAccount[];
}

const CsvButton: React.FC<ICsvButtonProps> = ({ data }: ICsvButtonProps) => {
  const { t } = useTranslation();

  const downloadCsvFile = () => {
    const element = document.createElement("a");
    const file = new Blob([generateCsv(data)]);
    element.href = URL.createObjectURL(file);
    element.download = "accounts.csv";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  return (
    <div className="pt-4">
      <button
        type="button"
        className="button-primary"
        onClick={() => downloadCsvFile()}
      >
        <DownloadIcon className="h-4 w-4 mr-2" />
        <p>{t("home.approved.download_csv")}</p>
      </button>
    </div>
  );
};

export default CsvButton;
