import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import AlertBanner, { Banner } from "../../../../common/components/AlertBanner";
import { User } from "../../../../types";
import AccountDetails from "./AccountDetails";
import {
  UserAddIcon,
  TrashIcon,
  DotsVerticalIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/outline";
import { Menu, Transition } from "@headlessui/react";
import DetailsEditModal from "./DetailsEditModal";
import NewUserModal from "./NewUserModal";
import Modal, { ModalStyle } from "../../../../common/components/Modal";
import useGetAccount from "../../../../common/data/useGetAccount";
import { userAccountdId } from "../../../../common/data/auth-header";
import UseDeleteUser from "../../../../common/data/useDeleteUser";
import useRefetch from "../../../../common/data/useRefetch";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

type PhoneFormData = {
  phone: string;
};

function buildPhoneNumberData(object: PhoneFormData) {
  return object;
}

const AccountPage = () => {
  //TODO edit these to have changing state, see login.tsx
  const [isEditingDetails, setIsEditingDetails] = useState(false);
  const [isCreatingUser, setIsCreatingUser] = useState(false);
  const [isDeletingUser, setIsDeletingUser] = useState(false);
  const [userToDelete, setUserToDelete] = useState<User>();
  const [isSuccess, setIsSuccess] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showPasswordOnce, setShowPasswordOnce] = useState(false);
  const [passwordOnce, setPasswordOnce] = useState("");
  const { data, isLoading, isFetching } = useGetAccount(userAccountdId());
  const refetchAsync = useRefetch();
  const { t } = useTranslation();

  function setPassWordData(newPassword: string) {
    setPasswordOnce(newPassword);
    setShowPasswordOnce(true);
  }

  const renderApplicationList = () => {
    return data?.users.map((user, personIdx) => (
      <tr
        key={user.id}
        className={personIdx % 2 === 0 ? "bg-white" : "bg-gray-50"}
      >
        <td className="table-col-bold">{user.name}</td>
        <td className="table-col">{user.phone}</td>
        <td className="table-col">{user.email}</td>
        <td className="table-col-action">
          <UserOptionsMenu
            onDelete={() => {
              setUserToDelete(user);
              console.log(`User to delete: ${JSON.stringify(userToDelete)}`);
              setIsDeletingUser(true);
            }}
            onEdit={() => {}}
            onEditPassword={() => {}}
          />
        </td>
      </tr>
    ));
  };

  const renderEmptyList = () => {
    return (
      <tr>
        <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          <p>{t("home.account.users_empty")}</p>
        </td>
      </tr>
    );
  };

  const renderLoading = () => {
    return (
      <tr>
        <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          <AlertBanner
            showExtraHelp={false}
            title={t("home.applications.loading")}
            type={Banner.LOADING}
          />
        </td>
      </tr>
    );
  };

  return (
    <div>
      <div className="px-2 pt-4 pb-32">
        {/* Account details card */}
        {errorMessage !== "" && (
          <AlertBanner
            showExtraHelp={false}
            title={errorMessage}
            type={Banner.ERROR}
          />
        )}
        {isSuccess !== "" && (
          <div className="mb-8">
            <AlertBanner
              showExtraHelp={false}
              title={isSuccess}
              type={Banner.SUCCESS}
            />
          </div>
        )}
        {isLoading ? (
          <div className="card">
            <AlertBanner
              showExtraHelp={false}
              title={t("home.applications.loading")}
              type={Banner.LOADING}
            />
          </div>
        ) : (
          <AccountDetails
            data={data}
            setIsEditing={setIsEditingDetails}
            loading={isFetching && !isLoading}
          />
        )}
        <h3 className="text-lg leading-6 font-medium text-gray-900  mt-6">
          {t("home.account.users")}
        </h3>
        {/* Users table */}
        <div className="table-container">
          <table className="table">
            <thead>
              <tr>
                <th scope="col" className="table-header-col">
                  {t("home.account.userName")}
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider"
                >
                  {t("home.account.userPhone")}
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider"
                >
                  {t("home.account.userEmail")}
                </th>
                <th scope="col" className="relative px-6 py-3 text-right">
                  <button
                    type="button"
                    className="relative inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                    onClick={() => setIsCreatingUser(true)}
                  >
                    <UserAddIcon
                      className="-ml-1 mr-2 h-5 w-5 text-gray-500"
                      aria-hidden="true"
                    />
                    <span>{t("home.account.add_user")}</span>
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading
                ? renderLoading()
                : data && data?.users.length > 0
                ? renderApplicationList()
                : renderEmptyList()}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        show={showPasswordOnce}
        setShow={setShowPasswordOnce}
        title={t("home.account.copy_the_password_title")}
        redTitle={passwordOnce}
        description={t("home.account.copy_the_password_description")}
        onConfirm={() => {
          setShowPasswordOnce(false);
        }}
        confirmLabel={t("home.account.ok")}
        style={ModalStyle.DANGER}
        icon={
          <ExclamationCircleIcon
            className="h-6 w-6 text-black"
            aria-hidden="true"
          />
        }
      />
      <DetailsEditModal
        data={data?.notes.split("|")[1]}
        show={isEditingDetails}
        setShow={setIsEditingDetails}
        successfullDetailUpdate={(message) =>
          refetchAsync() && setIsSuccess(message)
        }
      />
      <NewUserModal
        show={isCreatingUser}
        setShow={setIsCreatingUser}
        onSuccess={(message) => setIsSuccess(message)}
        onPasswordModalCallBack={(newPassword) =>
          refetchAsync() && setPassWordData(newPassword)
        }
      />
      <Modal
        show={isDeletingUser}
        setShow={setIsDeletingUser}
        title={`${t("home.account.delete_user_title")}`}
        redTitle={`${userToDelete?.name}`}
        description={t("home.account.delete_user_description")}
        onConfirm={() => {
          if (userToDelete?.phone != null) {
            var data = buildPhoneNumberData({ phone: userToDelete?.phone });
            var stringData = JSON.stringify(data);
            UseDeleteUser(stringData)
              .then((response) => {
                if (!response.ok) {
                  //console.log("delete error");
                  //console.log(response);
                  setIsDeletingUser(false);
                  setErrorMessage(t("home.account.error_delete_user"));
                } else {
                  setIsSuccess(t("home.account.success_delete_user"));
                  setIsDeletingUser(false);
                  refetchAsync();
                }
              })
              .catch((e) => {
                //console.log(`Delete Error: ${e}`);
                setIsDeletingUser(false);
                setErrorMessage(t("home.account.error_delete_user"));
              });
          } else {
            //console.log("delete error: phone missing");
            setIsDeletingUser(false);
            setErrorMessage(t("home.account.error_delete_user_phonenumber"));
          }
        }}
        confirmLabel={t("home.account.ok")}
        cancelLabel={t("home.account.cancel")}
        style={ModalStyle.DANGER}
        icon={
          <ExclamationCircleIcon
            className="h-6 w-6 text-black"
            aria-hidden="true"
          />
        }
      />
    </div>
  );
};

interface IUserOptionsMenuProps {
  onDelete: () => void;
  onEdit: () => void;
  onEditPassword: () => void;
}

const UserOptionsMenu: React.FC<IUserOptionsMenuProps> = ({
  onDelete,
  onEdit,
  onEditPassword,
}) => {
  const { t } = useTranslation();
  return (
    <Menu as="div" className="relative z-30 inline-block text-left">
      {({ open }) => (
        <>
          <div>
            <Menu.Button className="-m-2 p-2 flex text-sm font-medium rounded-md text-gray-400 bg-transparent hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
              <span className="sr-only">Open options</span>
              <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
            </Menu.Button>
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="mx-3 origin-top-right absolute right-2 top-0 w-48 mt-1 rounded-md shadow-lg z-10 bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
            >
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => onDelete()}
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "flex w-full px-2 py-3 text-sm font-medium items-center"
                      )}
                    >
                      <TrashIcon
                        className="mr-3 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span>{t("home.account.delete")}</span>
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default AccountPage;
