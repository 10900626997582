import { connect } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { AuthStatus, ICurrent } from "../../types";
import { eliminateRenderMethods } from "../../common/util/routes";

interface IProps extends RouteProps {
  authentication: AuthStatus;
}

const LoggedOutRoute = ({ authentication, ...props }: IProps) => {
  const safeProps = eliminateRenderMethods(props);
  if (authentication === AuthStatus.SIGNED_IN) {
    return (
      <Route {...safeProps}>
        <Redirect to="/" />
      </Route>
    );
  }
  return <Route {...props} />;
};

const mapStateToProps = (state: ICurrent) => ({
  authentication: state.authentication,
});

export default connect(mapStateToProps)(LoggedOutRoute);
