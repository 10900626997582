import React, { useState } from "react";
import BackButton from "../../../../common/components/BackButton";
import DetailsCard from "./DetailsCard";
import RejectModal from "./RejectModal";
import ApproveModal from "./ApproveModal";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AlertBanner, { Banner } from "../../../../common/components/AlertBanner";
import useReject, { RejectProps } from "../../../../common/data/useReject";
import useApprove from "../../../../common/data/useApprove";
import useGetApplication from "../../../../common/data/useGetApplication";

const ApplicationDetails = () => {
  const { id } = useParams<{ id: string }>();

  const { data, isError, isLoading } = useGetApplication(id);
  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState(false);
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [successIcabbUrl, setSuccessIcabbUrl] = useState("");
  const reject = useReject();
  const approve = useApprove();

  const history = useHistory();
  const { t } = useTranslation();

  const onDecline = () => {
    setIsDeclineModalOpen(true);
  };

  const onDeclineModalConfirm = (message: string) => {
    setIsDeclineModalOpen(false);
    const rejectProps: RejectProps = { id, message };
    reject.mutate(rejectProps, {
      onSuccess: (data, variables, context) => {
        console.log("rejected data");
        console.log(data);
        setIsSuccess(true);
        setIsApproved(false);
      },
      onError: (err: any) => {
        //console.log(`Reject Error: ${err}`);
      },
    });
  };

  const onAccept = () => {
    setIsAcceptModalOpen(true);
  };

  const onAcceptModalConfirm = () => {
    setIsAcceptModalOpen(false);
    approve.mutate(id, {
      onSuccess: (data, variables, context) => {
        if (data) {
          data.json().then((responsedata: any) => {
            const url: string = String(responsedata["url"]);
            setSuccessIcabbUrl(url);
            setIsApproved(true);
          });
        }
        setIsSuccess(true);
      },
      onError: (err: any) => {
        console.log(`Approve Error: ${err}`);
      },
    });
  };

  const renderAccountData = () => {
    return (
      <div>
        {isError ? (
          <AlertBanner
            title={t("home.applications.alertBanner_title_error")}
            showExtraHelp={false}
            type={Banner.ERROR}
            actionLabel={t("home.applications.try_again_button")}
          />
        ) : (
          <div></div>
        )}
        {isSuccess ? (
          <AlertBanner
            title={t("home.applications.alertBanner_title_success")}
            showExtraHelp={isApproved}
            messagetwo={t("home.applications.success_icabb_description")}
            link_title={t("home.applications.success_icabb_title")}
            link={successIcabbUrl}
            image={t("home.applications.success_image_link")}
            type={Banner.SUCCESS}
            actionLabel={t("home.applications.return_button")}
            onActionClick={() => history.goBack()}
          />
        ) : data === undefined && !isLoading ? (
          <AlertBanner
            title={t("home.applications.alertBanner_not_found")}
            showExtraHelp={false}
            type={Banner.INFO}
            actionLabel={t("home.applications.return_button")}
            onActionClick={() => history.goBack()}
          />
        ) : isLoading ? (
          <AlertBanner
            title={t("home.applications.loading")}
            showExtraHelp={false}
            type={Banner.LOADING}
            actionLabel={t("home.applications.return_button")}
            onActionClick={() => history.goBack()}
          />
        ) : (
          <DetailsCard
            data={data}
            loading={isLoading}
            onDecline={onDecline}
            onAccept={onAccept}
          />
        )}
      </div>
    );
  };

  return (
    <div className="max-w-3xl mx-auto">
      <div className="pt-6 pb-2">
        <BackButton />
      </div>
      {renderAccountData()}
      <RejectModal
        show={isDeclineModalOpen}
        setShow={setIsDeclineModalOpen}
        onConfirm={(text: string) => onDeclineModalConfirm(text)}
      />
      <ApproveModal
        show={isAcceptModalOpen}
        setShow={setIsAcceptModalOpen}
        onConfirm={() => onAcceptModalConfirm()}
      />
    </div>
  );
};

export default ApplicationDetails;
