import { AuthenticationAction, AUTHENTICATE } from "../actions/current";
import { AuthStatus, ICurrent } from "../../types";

export default function currentReducer(
  state: ICurrent = {
    uuid: null,
    authentication: AuthStatus.NONE,
  },
  action: AuthenticationAction
): ICurrent {
  switch (action.type) {
    case AUTHENTICATE:
      return {
        ...state,
        uuid: "placeholder-uuid",
        authentication: action.payload,
      };
    default:
      return {
        ...state,
        uuid: "placeholder-uuid",
      };
  }
}
