import { useQueryClient } from "react-query";

const useRefetch = () => {
  const queryClient = useQueryClient();
  return async () => {
    //console.log("await async");
    //await queryClient.resetQueries("account");
    await queryClient.invalidateQueries("account", {
      refetchActive: true,
      refetchInactive: false,
    });
  };
};
export default useRefetch;
