import { Route, Switch } from "react-router-dom";

import AppRoute from "./AppRoute";
import LoggedOutRoute from "./LoggedOutRoute";
import AccountPage from "../components/pages/account/AccountPage";

import NotFound from "../components/pages/NotFound";
import Register from "../components/pages/Register";
import LogIn from "../components/pages/LogIn";

const Pages = () => (
  <Switch>
    <AppRoute exact path="/" component={AccountPage} />

    <LoggedOutRoute exact path="/" component={LogIn} />
    <LoggedOutRoute exact path="/login" component={LogIn} />
    <LoggedOutRoute exact path="/register" component={Register} />

    <Route component={NotFound} />
  </Switch>
);

export default Pages;
