/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import React, { useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { UserAddIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import { useForm, SubmitHandler } from "react-hook-form";
import UsePostUser from "../../../../common/data/usePostUser";
import generator from "generate-password";

interface IProps {
  show: boolean;
  setShow: (show: boolean) => void;
  onPasswordModalCallBack: (newPassword: string) => void;
  onSuccess: (message: string) => void;
}

type NewUserFormData = {
  name: string;
  phone: string;
  email: string;
  password: string;
};

const NewUserModal: React.FC<IProps> = ({
  show,
  setShow,
  onPasswordModalCallBack,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const [isError, setisError] = useState("");

  const { register, handleSubmit, setValue } = useForm<NewUserFormData>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
  });

  useEffect(() => {
    var newPassString = generator.generate({
      length: 10,
      numbers: true,
    });
    setValue("password", newPassString, {
      shouldValidate: true,
      shouldDirty: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit: SubmitHandler<NewUserFormData> = (data) => {
    var stringData = JSON.stringify(data);
    console.log(`Submit: ${JSON.stringify(data)}`);

    UsePostUser(stringData)
      .then((response) => {
        if (!response.ok) {
          setisError(t("home.account.fail_new_user"));
        } else {
          onSuccess(t("home.account.success_new_user"));
          onPasswordModalCallBack(data.password);
          setShow(false);
        }
      })
      .catch((e) => {
        //console.log(`Approve Error: ${e}`);
        setisError(t("home.account.fail_new_user"));
      });
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={show}
        // Stop modal from closing on outside click
        onClose={() => null}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <form
              className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-primary sm:mx-0 sm:h-10 sm:w-10">
                    <UserAddIcon
                      className="h-6 w-6 text-black"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left flex-grow">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      {t("home.account.add_user")}
                    </Dialog.Title>
                    <div className="pt-5 pb-5>">
                      {isError !== "" && (
                        <div className="text-red-800">{isError}</div>
                      )}
                    </div>
                    <div className="sm:pt-2">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        {t("home.account.userName")}
                      </label>
                      <div className="mt-1 sm:mt-0">
                        <input
                          {...register("name")}
                          type="text"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-black sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="sm:pt-2">
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        {t("home.account.userPhone")}
                      </label>
                      <div className="mt-1 sm:mt-0">
                        <input
                          {...register("phone")}
                          type="text"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-black sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="sm:pt-2">
                      <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        {t("home.account.password")}
                      </label>
                      <div className="mt-1 sm:mt-0">
                        <input
                          {...register("password")}
                          type="text"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-black sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="sm:pt-2">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        {t("home.account.userEmail")}
                      </label>
                      <div className="mt-1 sm:mt-0">
                        <input
                          {...register("email")}
                          type="text"
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-black sm:text-sm"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="submit"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-black hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:ml-3 sm:w-auto sm:text-sm"
                >
                  {t("home.account.add_user")}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setShow(false)}
                >
                  {t("home.account.cancel")}
                </button>
              </div>
            </form>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default NewUserModal;
