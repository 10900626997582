import { XCircleIcon } from "@heroicons/react/solid";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { logInApp } from "../../actions/current";
import LogoHeader from "../../../common/components/LogoHeader";
import { Users } from "../../../types";
import history from "../../../history";

type Initial = {
  type: "INITIAL";
};
type SelectUser = {
  type: "SELECT_USER";
};
type Loading = {
  type: "LOADING";
};
type Error = {
  type: "ERROR";
};

type LoadingState = Initial | SelectUser | Loading | Error;

interface IProps {
  logInConnect: () => void;
}

type FormOneData = {
  username: string;
};
type FormTwoData = {
  selected_user: string;
  password: string;
};

const LogIn = ({ logInConnect }: IProps) => {
  const { t } = useTranslation();
  const [loadingState, setLoadingState] = useState<LoadingState>({
    type: "INITIAL",
  });
  const [items, setItems] = useState<Users[] | []>([]);
  const [errorMessage, SetErrorMessage] = useState("");
  const [username, setUsername] = useState("");

  const setLoadingStateErrorSelectUser = () => {
    SetErrorMessage(t("app.login.initialError"));
    setLoadingState({ type: "SELECT_USER" });
    localStorage.removeItem("user-key"); // TODO: Move this to better place
    localStorage.removeItem("user-info");
  };
  const setLoadingStateErrorInitial = () => {
    SetErrorMessage(t("app.login.selectError"));
    setLoadingState({ type: "INITIAL" });
  };
  const setLoadingStateSelectUser = (users: [Users], username: string) => {
    SetErrorMessage("");
    setUsername(username);
    setItems(users);
    setLoadingState({ type: "SELECT_USER" });
  };
  const setLoadingStateLoading = () => {
    SetErrorMessage("");
    setLoadingState({ type: "LOADING" });
  };
  const setLoadingStateSuccess = () => {
    SetErrorMessage("");
    logInConnect();
    history.push("/");
  };
  return (
    <div
      className="bg-cover bg-center min-height"
      style={{
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        backgroundImage: `url(/images/bg_taksi.jpg)`,
      }}
    >
      <div className="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <LogoHeader />
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            {errorMessage !== "" && <ErrorView message={errorMessage} />}
            {loadingState.type === "INITIAL" && (
              <InitialView
                onSuccessBlock={setLoadingStateSelectUser}
                onLoadingBlock={setLoadingStateLoading}
                onErrorBlock={setLoadingStateErrorInitial}
              />
            )}
            {loadingState.type === "LOADING" && <LoadingView />}

            {loadingState.type === "SELECT_USER" && (
              <SelectUserView
                values={items}
                user={username}
                onErrorBlock={setLoadingStateErrorSelectUser}
                onSuccessBlock={setLoadingStateSuccess}
                onLoadingBlock={setLoadingStateLoading}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

interface SelectUserProps {
  values?: Users[] | null;
  user: string;
  onSuccessBlock: () => void;
  onLoadingBlock: () => void;
  onErrorBlock: () => void;
}

const SelectUserView = ({
  onSuccessBlock,
  onLoadingBlock,
  onErrorBlock,
  values,
  user,
}: SelectUserProps) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    //watch,
    //getValues,
    //setValue,
    //formState: { errors },
  } = useForm<FormTwoData>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
  });

  const onSelectUserSubmit: SubmitHandler<FormTwoData> = (data) => {
    if (data.selected_user === t("app.login.select_label")) {
      onErrorBlock();
    } else {
      //console.log(data);
      let userObject = values?.find((i) => i.id === data.selected_user);
      var password = data.password;
      onLoadingBlock();
      if (userObject != null) {
        try {
          fetchAccount(`/api/account/${user}`, userObject, password, user).then(
            (response) => {
              if (response.ok) {
                onSuccessBlock();
              } else {
                onErrorBlock();
              }
            }
          );
        } catch (e) {
          onErrorBlock();
          localStorage.clear();
        }
      } else {
        onErrorBlock();
        localStorage.clear();
      }
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSelectUserSubmit)}>
        <h3 className="text-lg leading-6 font-medium text-gray-900 pb-5">
          {t("app.login.select_user_title")}
        </h3>
        <label
          htmlFor="select_user"
          className="block text-sm font-medium text-gray-700"
        >
          {t("app.login.select_label")}
        </label>
        <select
          {...register("selected_user")}
          className="mt-1 mb-5 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          required
        >
          <option>{t("app.login.select_label")}</option>
          {values?.map((element) => (
            <option key={element.id} value={element.id}>
              {element.name}
            </option>
          ))}
        </select>
        <label
          htmlFor="password"
          className="block text-sm font-medium text-gray-700"
        >
          {t("app.login.password_label")}
        </label>
        <div className="mt-1 mb-5">
          <input
            {...register("password")}
            id="password"
            name="password"
            type="password"
            required
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <button
          type="submit"
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-black bg-primary hover:bg-black hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          {t("app.login.post_title")}
        </button>
      </form>
    </div>
  );
};

const LoadingView = () => {
  return (
    <div>
      <div className="pb-10">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-20 w-20 ml-auto mr-auto block"></div>
      </div>
    </div>
  );
};

const fetchAccount = async (
  url: string,
  userObject: Users,
  password: string,
  user: string
) => {
  const requestHeaders: HeadersInit = new Headers();
  const varstring = user + ":" + userObject?.phone + ":" + password;
  //alert(varstring);
  const data = window.btoa(varstring);
  userObject.accountId = user;
  requestHeaders.set("Authorization", `Basic ${data}`);
  localStorage.setItem("user-key", JSON.stringify(data));
  localStorage.setItem("user-info", JSON.stringify(userObject));

  return fetch(url, {
    method: "GET",
    headers: requestHeaders,
  });
};

interface InitialViewProps {
  onSuccessBlock: (n: [Users], u: string) => void;
  onLoadingBlock: () => void;
  onErrorBlock: () => void;
}

const requestHeaders: HeadersInit = new Headers();
requestHeaders.set("X-API-KEY", "A9tRy6XE4so5FKruvbEoTUVZUZHPKKdj");

const fetchUsers = async (url: string) => {
  return fetch(url, {
    method: "GET",
    headers: requestHeaders,
  });
};

const InitialView = ({
  onSuccessBlock,
  onLoadingBlock,
  onErrorBlock,
}: InitialViewProps) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    /*watch,
    getValues,
    setValue,
    formState: { errors },*/
  } = useForm<FormOneData>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
  });

  const OnStepOneSubmit: SubmitHandler<FormOneData> = (formdata) => {
    onLoadingBlock();
    let username = formdata.username;
    try {
      fetchUsers(`/api/account/${username}/users`).then((response) => {
        if (response.ok) {
          response.json().then((responsedata: [Users]) => {
            onSuccessBlock(responsedata, username);
          });
        } else {
          onErrorBlock();
        }
      });
    } catch (e) {
      onErrorBlock();
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(OnStepOneSubmit)}>
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900 pb-5">
            {t("app.login.title")}
          </h3>
          <label
            htmlFor="username"
            className="block text-sm font-medium text-gray-700"
          >
            {t("app.login.pin_title")}
          </label>
          <div className="mt-1 mb-5">
            <input
              {...register("username")}
              id="username"
              name="username"
              type="text"
              required
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>
        <div>
          <button
            type="submit"
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-black bg-primary hover:bg-black hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {t("app.login.next")}
          </button>
        </div>
        <div className="mt-6">
          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white text-gray-500">
                {t("app.login.or_title")}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="text-sm pt-8">
              <Link className="underline" to="/register">
                {t("app.login.register_link_title")}
              </Link>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

interface ErrorViewProps {
  message?: string | null;
}

const ErrorView = (props?: ErrorViewProps) => {
  const { t } = useTranslation();
  return (
    <div className="rounded-md bg-red-50 p-4 mb-6">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">
            {t("register_error_title")}
          </h3>
          <div className="mt-2 text-sm text-red-700">
            <ul className="list-disc pl-5 space-y-1">
              {props?.message ?? <li>{props?.message}</li>}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  logInConnect: logInApp,
};

export default connect(null, mapDispatchToProps)(LogIn);
