import { useTranslation } from "react-i18next";
import useGetApproved from "../../../../common/data/useGetApproved";
import CsvButton from "./CsvButton";
import AlertBanner, { Banner } from "../../../../common/components/AlertBanner";
import { ApprovedAccount } from "../../../../types";

const Approved = () => {
  const { data, isLoading } = useGetApproved();
  const { t } = useTranslation();

  const renderApplicationList = () => {
    return data?.map((user: ApprovedAccount, personIdx) => (
      <tr
        key={user.id}
        className={personIdx % 2 === 0 ? "bg-gray" : "bg-gray-50"}
      >
        <td className="table-col-bold">{user.name}</td>
        <td className="table-col">{user.businessId}</td>
        <td className="table-col">{user.contactPerson.email}</td>
        <td className="table-col">{user.iCabbiAccount.identifier}</td>
        <td className="table-col">{user.billingInformation.type}</td>
      </tr>
    ));
  };

  const renderEmptyList = () => {
    return (
      <tr>
        <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          <p>{t("home.applications.empty")}</p>
        </td>
      </tr>
    );
  };

  const renderLoading = () => {
    return (
      <tr>
        <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          <AlertBanner
            showExtraHelp={false}
            title={t("home.applications.loading")}
            type={Banner.LOADING}
          />
        </td>
      </tr>
    );
  };

  return (
    <div>
      <h3 className="content-title">
        {t("home.approved.approved_applications")}
      </h3>
      {!isLoading ? (
        <div>
          <CsvButton data={data}></CsvButton>
        </div>
      ) : (
        <div></div>
      )}
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              <th scope="col" className="table-header-col">
                {t("home.applications.companyName")}
              </th>
              <th scope="col" className="table-header-col">
                {t("home.applications.businessID")}
              </th>
              <th scope="col" className="table-header-col">
                {t("home.applications.contactEmail")}
              </th>
              <th scope="col" className="table-header-col">
                {t("home.approved.icabbi_id")}
              </th>
              <th scope="col" className="table-header-col">
                {t("home.approved.type")}
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading
              ? renderLoading()
              : data && data?.length > 0
              ? renderApplicationList()
              : renderEmptyList()}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Approved;
