import { useQuery } from "react-query";
import { Account } from "../../types";
import { authHeader } from "./auth-header";

const url = "/api/account";

const fetcher = async (id: string) => {
  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set("Authorization", authHeader());

  return fetch(`${url}/${id}`, {
    method: "GET",
    headers: requestHeaders,
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(String(response.status));
      } else {
        return response.json();
      }
    })
    .catch((e) => console.log(e));
};

const useGetAccount = (id: string) => {
  return useQuery<Account>("account", () => fetcher(id), {
    onSuccess: () => {
      //queryClient.invalidateQueries("account");
    },
  });
};

export default useGetAccount;
