import { Auth } from "aws-amplify";
import { useMutation } from "react-query";
const url = "/api/application";

const fetcher = async (id: string, action: string, message?: string) => {
  const requestHeaders: HeadersInit = new Headers();
  const session = await Auth.currentSession();
  const token = session.getAccessToken().getJwtToken();
  requestHeaders.set("Authorization", `Bearer ${token}`);

  return fetch(`${url}/${id}/${action}`, {
    method: "POST",
    headers: requestHeaders,
    body: message ? JSON.stringify({ message: message }) : JSON.stringify({}),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(String(response.status));
      } else {
        return response;
      }
    })
    .catch((e) => console.log(e));
};

const useApprove = () => {
  const mutation = useMutation((id: string, message?: string) =>
    fetcher(id, "approve", message)
  );
  return mutation;
};

export default useApprove;
