import React from "react";
import fetchIntercept from "fetch-intercept";
import { connect } from "react-redux";
import Pages from "./routes/Pages";
import { logInApp, logOut } from "./actions/current";
import { AuthStatus, ICurrent } from "../types";
import NavUser from "./components/NavUser";

fetchIntercept.register({
  response: function (response) {
    if (response.status === 403 || response.status === 401) {
      if (
        localStorage.getItem("user-key") ||
        localStorage.getItem("user-info")
      ) {
        logOut();
        alert("No access rights, you have been logged out");
        window.location.reload();
      }
    }
    return response;
  },
});

interface IProps {
  authentication: AuthStatus;
  logInApp: () => void;
}

const AppUser = ({ authentication, logInApp }: IProps) => {
  React.useEffect(() => {
    // App user sign in check
    if (localStorage.getItem("user-key")) {
      logInApp();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return authentication !== AuthStatus.NONE ? (
    <NavUser>
      <Pages />
    </NavUser>
  ) : (
    <Pages />
  );
};

const mapDispatchToProps = {
  logInApp: logInApp,
};

const mapStateToProps = (state: ICurrent) => ({
  authentication: state.authentication,
});

export default connect(mapStateToProps, mapDispatchToProps)(AppUser);
