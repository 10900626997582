import * as React from "react";
import { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { UserIcon } from "@heroicons/react/outline";
import { logOut } from "../actions/current";
import { useTranslation } from "react-i18next";
import { ICurrent, Navigation, Users } from "../../types";
import Nav from "../../common/components/Nav";

type Props = {
  signOut: () => void;
};

const NavUser: React.FC<Props> = (props) => {
  const [userName, setUserName] = useState("");

  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    const userObject: Users = JSON.parse(
      localStorage.getItem("user-info") || "{}"
    );
    setUserName(userObject.name || "");
  }, []);

  const navigation: Navigation[] = useMemo(
    () => [
      {
        name: t("home.account.title"),
        to: "/",
        icon: UserIcon,
        current: location.pathname === "/",
      },
    ],
    [t, location]
  );

  const history = useHistory();

  const onSignOut = () => {
    props.signOut();
    history.push("/login");
    window.location.reload();
  };

  return (
    <Nav navigation={navigation} userName={userName} signOut={onSignOut}>
      {props.children}
    </Nav>
  );
};

const mapStateToProps = (state: ICurrent) => ({});

const mapDispatchToProps = {
  signOut: logOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavUser);
