import * as React from "react";
import { useEffect, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { InboxIcon, CheckCircleIcon } from "@heroicons/react/outline";
import { Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";
import { Navigation } from "../../types";
import Nav from "../../common/components/Nav";

const NavAdmin: React.FC = (props) => {
  const [userName, setUserName] = useState("");

  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    Auth.currentUserInfo().then((user: any) => {
      if (user) {
        let email = user.attributes["email"];
        setUserName(email);
      }
    });
  }, []);

  const navigation: Navigation[] = useMemo(
    () => [
      {
        name: t("home.applications.title"),
        to: "/admin/home",
        icon: InboxIcon,
        current:
          location.pathname === "/admin/home" ||
          location.pathname.includes("/admin/a/"),
      },
      {
        name: t("home.approved.title"),
        to: "/admin/approved",
        icon: CheckCircleIcon,
        current: location.pathname === "/admin/approved",
      },
    ],
    [location, t]
  );

  const signOut = async function () {
    try {
      await Auth.signOut();
    } catch {
      console.error("Error signing user out");
    }
  };

  return (
    <Nav navigation={navigation} userName={userName} signOut={signOut}>
      {props.children}
    </Nav>
  );
};

export default NavAdmin;
