import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import { SelectorIcon, LogoutIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import { Navigation } from "../../types";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

interface IProps {
  navigation: Navigation[];
  userName: string;
  signOut: () => void;
  onPageChange: (page: Navigation) => void;
}

const SideNav = ({ navigation, userName, signOut, onPageChange }: IProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex items-center flex-shrink-0 px-6">
        <img
          className="h-auto w-auto"
          src={"/images/logoSVGWhite.svg"}
          alt="Taksi Tampere logo"
        />
      </div>
      <div className="flex-1 flex flex-col overflow-y-auto">
        <Menu as="div" className="px-3 mt-6 relative inline-block text-left">
          {({ open }) => (
            <>
              <div>
                <Menu.Button className="group w-full bg-black rounded-md px-3.5 py-2 text-sm text-left font-medium hover:bg-black hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary focus:ring-secondary">
                  <span className="flex w-full justify-between items-center">
                    <span className="flex min-w-0 items-center justify-between space-x-3">
                      <span className="flex-1 flex flex-col min-w-0">
                        <p className="text-white text-sm font-medium truncate group-hover:text-gray-200">
                          {userName}
                        </p>
                      </span>
                    </span>
                    <SelectorIcon
                      className="flex-shrink-0 h-5 w-5 text-white group-hover:text-gray-200"
                      aria-hidden="true"
                    />
                  </span>
                </Menu.Button>
              </div>
              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="z-10 origin-top absolute right-0 left-0 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none ml-2 mr-2"
                >
                  <div className="p-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          tabIndex={0}
                          onClick={signOut}
                          onKeyDown={signOut}
                          className={classNames(
                            active
                              ? "bg-primary text-black rounded-md"
                              : "text-gray-700",
                            "flex w-full px-2 py-3 text-sm font-medium items-center"
                          )}
                        >
                          {t("home.logout")}
                          <LogoutIcon
                            className={"ml-2 -mr-1 h-4 w-4"}
                            aria-hidden="true"
                          />
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>

        <nav className="px-3 mt-6">
          <div className="space-y-1">
            {navigation.map((item) => (
              <Link
                key={item.name}
                to={item.to}
                onClick={() => onPageChange(item)}
                className={classNames(
                  item.current
                    ? "bg-primary text-black"
                    : "text-white hover:text-black hover:bg-gray-50",
                  "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                )}
                aria-current={item.current ? "page" : undefined}
              >
                <item.icon
                  className={classNames(
                    item.current
                      ? "text-black"
                      : "text-white group-hover:text-black",
                    "mr-3 h-6 w-6"
                  )}
                  aria-hidden="true"
                />
                {item.name}
              </Link>
            ))}
          </div>
        </nav>
      </div>
    </div>
  );
};

export default SideNav;
