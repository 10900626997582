import { ThunkDispatch as Dispatch } from "redux-thunk";
import { AuthStatus } from "../../types";

export const AUTHENTICATE = "AUTHENTICATE";

export interface AuthenticationAction {
  type: typeof AUTHENTICATE;
  payload: AuthStatus;
}

export const logInApp = () => ({
  type: AUTHENTICATE,
  payload: AuthStatus.SIGNED_IN,
});

export const logOut = () => {
  localStorage.removeItem("user-key");
  localStorage.removeItem("user-info");
  return async (dispatch: Dispatch<AuthenticationAction, {}, any>) => {
    dispatch({
      type: AUTHENTICATE,
      payload: AuthStatus.NONE,
    });
  };
};
