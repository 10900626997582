import { Auth } from "aws-amplify";
import { useQuery } from "react-query";
import { AccountApplication, ApprovedAccount } from "../../types";

const url = "/api/application";

const fetcher = async () => {
  const requestHeaders: HeadersInit = new Headers();
  const session = await Auth.currentSession();
  const token = session.getAccessToken().getJwtToken();
  requestHeaders.set("Authorization", `Bearer ${token}`);

  return fetch(url, {
    method: "GET",
    headers: requestHeaders,
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(String(response.status));
      } else {
        return response.json();
      }
    })
    .catch((e) => console.log(e));
};

const useGetApproved = () => {
  const result = useQuery<ApprovedAccount[]>("approved", () => fetcher());
  const pendingAccounts: ApprovedAccount[] | undefined = result.data?.filter(
    (acc: ApprovedAccount | AccountApplication) => acc.status === "approved"
  );

  result.data = pendingAccounts;
  return result;
};

export default useGetApproved;
