import React from "react";
import { useHistory } from "react-router";
import { ChevronLeftIcon } from "@heroicons/react/solid";

const BackButton = () => {
  const history = useHistory();

  return (
    <div>
      <button
        type="button"
        className="inline-flex items-center px-2 py-2 border border-transparent focus:outline-none text-sm leading-4 font-medium rounded-md shadow-sm bg-primary text-black hover:bg-primary"
        onClick={() => history.goBack()}
      >
        <ChevronLeftIcon className="h-4 w-4" />
      </button>
    </div>
  );
};

export default BackButton;
