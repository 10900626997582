import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useGetApplications from "../../../../common/data/useGetAllApplications";
import AlertBanner, { Banner } from "../../../../common/components/AlertBanner";

const Applications = () => {
  const { data, isLoading } = useGetApplications();

  const { t } = useTranslation();

  const renderApplicationList = () => {
    return data?.map((user, personIdx) => (
      <tr
        key={user.id}
        className={personIdx % 2 === 0 ? "bg-white" : "bg-gray-50"}
      >
        <td className="table-col-bold">{user.name}</td>
        <td className="table-col">{user.businessId}</td>
        <td className="table-col">{user.contactPerson.email}</td>
        <td className="table-col">
          {new Date(user.createdAt).toLocaleString()}
        </td>
        <td className="table-col">{user.billingInformation.type}</td>
        <td className="table-col-action">
          <Link to={`/admin/a/${user.id}`}>
            <button className="button-primary">
              {t("home.applications.show_button")}
            </button>
          </Link>
        </td>
      </tr>
    ));
  };

  const renderEmptyList = () => {
    return (
      <tr>
        <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          <p>{t("home.applications.empty")}</p>
        </td>
      </tr>
    );
  };

  const renderLoading = () => {
    return (
      <tr>
        <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          <AlertBanner
            showExtraHelp={false}
            title={t("home.applications.loading")}
            type={Banner.LOADING}
          />
        </td>
      </tr>
    );
  };

  return (
    <div>
      <h3 className="content-title">
        {t("home.applications.open_applications")}
      </h3>
      <div className="table-container">
        <table className="min-w-full divide-y divide-gray-200 bg-white rounded-lg">
          <thead>
            <tr>
              <th scope="col" className="table-header-col">
                {t("home.applications.companyName")}
              </th>
              <th scope="col" className="table-header-col">
                {t("home.applications.businessID")}
              </th>
              <th scope="col" className="table-header-col">
                {t("home.applications.contactEmail")}
              </th>
              <th scope="col" className="table-header-col">
                {t("home.applications.date")}
              </th>
              <th scope="col" className="table-header-col">
                {t("home.applications.type")}
              </th>
              <th scope="col" className="relative px-6 py-3">
                <span className="sr-only"></span>
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading
              ? renderLoading()
              : data && data?.length > 0
              ? renderApplicationList()
              : renderEmptyList()}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Applications;
