import { authHeader, userAccountdId } from "./auth-header";

const url = "/api/account";
const url2 = "notes";

/*
{
  "notes": "string"
}
*/

const fetcher = async (accountId: string, data: string) => {
  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set("Authorization", authHeader());

  return fetch(`${url}/${accountId}/${url2}`, {
    method: "POST",
    headers: requestHeaders,
    body: data,
  });
};

const UsePostNotes = (data: string) => {
  return fetcher(userAccountdId(), data);
};

export default UsePostNotes;
