import { useTranslation } from "react-i18next";
import { useForm, SubmitHandler } from "react-hook-form";
import { useState } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import { XCircleIcon } from "@heroicons/react/solid";
import { BillingInformation, ContactPerson } from "../../../types";

type Initial = {
  type: "INITIAL";
};
type Loading = {
  type: "LOADING";
};
type Error = {
  type: "ERROR";
};
type Success = {
  type: "SUCCESS";
};

type LoadingState = Initial | Loading | Success | Error;

const fetcher = async (url: string, body: string) => {
  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set("X-API-KEY", "A9tRy6XE4so5FKruvbEoTUVZUZHPKKdj");
  return fetch(url, {
    method: "POST",
    body: body,
    headers: requestHeaders,
  });
};

const isYTunnus = (x: string): boolean => {
  const regex = /^\d{7}-\d$/;
  if (regex.test(x)) {
    const [prefix, suffix] = x.split("-");
    const [n1, n2, n3, n4, n5, n6, n7] = Array.from(prefix!).map((x) =>
      parseInt(x, 10)
    );
    const checksum = parseInt(suffix!, 10);
    const sum =
      n1! * 7 + n2! * 9 + n3! * 10 + n4! * 5 + n5! * 8 + n6! * 4 + n7! * 2;
    const remainder = sum % 11;
    if (remainder === 0) {
      return checksum === 0;
    } else if (remainder === 1) {
      return false;
    } else {
      return checksum === 11 - remainder;
    }
  } else {
    return false;
  }
};

type FormData = {
  name: string;
  businessId: string;
  nickname: string;
  terms?: boolean;
  contactPerson: ContactPerson;
  billingInformation: BillingInformation;
  billingInformationType?: BillingInformation["type"];
  cashOnly?: boolean;
};

const Register = () => {
  const [errorState, setErrorState] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [sendToEmail, setSendToEmail] = useState("");
  const [loadingState, setLoadingState] = useState<LoadingState>({
    type: "INITIAL",
  });

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: { billingInformationType: "einvoicing" },
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
  });
  const watchBillingInformationType = watch("billingInformationType");

  // eslint-disable-next-line
  var responseStatus = null;
  const onSubmit: SubmitHandler<FormData> = (data) => {
    setLoadingState({ type: "LOADING" });

    if (data["terms"] === null || data["terms"] === false) {
      setErrorMessage(t("register.error_accept_terms"));
    } else {
      delete data["billingInformationType"];
      delete data["terms"];
      //alert(JSON.stringify(data));
      setSendToEmail(data.contactPerson.email);
      try {
        fetcher("/api/application", JSON.stringify(data)).then((response) => {
          if (response.ok) {
            //console.log("Response was: 200 Success");
            // eslint-disable-next-line
            responseStatus = response.status;
            setLoadingState({ type: "SUCCESS" });
          } else {
            const data = response.json();
            data.then((responseJson) => {
              //console.log("Response was: " + response.status);
              //console.log(responseJson[0].message);
              setErrorMessage(responseJson[0].message);
            });
            setErrorState(String(response.status));
            setLoadingState({ type: "ERROR" });
          }
        });
      } catch (e) {
        setLoadingState({ type: "ERROR" });
      }
    }
  };

  var formError = false;

  switch (loadingState.type) {
    case "INITIAL":
      break;
    case "LOADING":
      return <SyncLoader />;
    case "SUCCESS":
      return <SuccessView sendToEmail={sendToEmail} />;
    case "ERROR":
      formError = true;
      break;
  }

  return (
    <div
      className="bg-cover bg-center min-height"
      style={{
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        backgroundImage: `url(/images/bg_taksi.jpg)`,
      }}
    >
      <div className="sm:mx-auto sm:w-full max-w-3xl">
        <div className="pt-40 text-center text-5xl font-extrabold text-black">
          <img
            className="max-h-40 w-auto ml-auto mr-auto block"
            src={"/images/logo-taksitampere.png"}
            alt="Taksi Tampere logo"
          />
        </div>
      </div>
      <div className="pt-8 sm:mx-auto sm:w-full max-w-3xl pb-40">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <h1 className="pt-5 text-center text-3xl font-extrabold text-black pb-12">
            {t("register_title")}
          </h1>
          {formError && (
            <ErrorView status={errorState} message={errorMessage} />
          )}
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {t("register_companyinfo_title")}
            </h3>
            <div className="mt-6 grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-4">
              <div className="sm:col-span-2">
                <label
                  htmlFor="nickname"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("register_nickname_label")}
                </label>
                <div className="mt-1">
                  <input
                    {...register("nickname")}
                    id="nickname"
                    name="nickname"
                    type="text"
                    placeholder={t("register.nickname_placeholder")}
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
            </div>
            <div className="pt-3 pb-3">
              <hr></hr>
            </div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {t("register_contactinfo_title")}
            </h3>
            <div className="mt-6 grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-4">
              <div className="sm:col-span-2">
                <label
                  htmlFor="contactPerson.name"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("register_contactName_label")}
                </label>
                <div className="mt-1">
                  <input
                    {...register("contactPerson.name")}
                    id="contactPerson.name"
                    name="contactPerson.name"
                    type="text"
                    placeholder={t("register.contactName_placeholder")}
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
            </div>
            <div className="mt-6 grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-4">
              <div className="sm:col-span-2">
                <label
                  htmlFor="contactPerson.phone"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("register_contactPhone_label")}
                </label>
                <div className="mt-1">
                  <input
                    {...register("contactPerson.phone", {
                      required: true,
                      validate: isValidPhoneNumber,
                    })}
                    id="contactPerson.phone"
                    name="contactPerson.phone"
                    type="text"
                    placeholder={t("register.contactPhone_placeholder")}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  {errors.contactPerson?.phone && (
                    <p className="text-sm text-red-700 mt-1">
                      {t("register_phone_error")}
                    </p>
                  )}
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="contactPerson.email"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("register_contactEmail_label")}
                </label>
                <div className="mt-1">
                  <input
                    {...register("contactPerson.email", {
                      required: true,
                      pattern: /^\S+@\S+$/i,
                    })}
                    id="contactPerson.email"
                    name="contactPerson.email"
                    type="text"
                    placeholder={t("register.contactEmail_placeholder")}
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  {errors.contactPerson?.email && (
                    <p className="text-sm text-red-700 mt-1">
                      {t("register_contactPerson_email_error")}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="pt-3 pb-3">
              <hr></hr>
            </div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {t("register_billinginfo_title")}
            </h3>
            <div className="mt-6 grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-4">
              <div className="sm:col-span-2">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("register_companyName_label")}
                </label>
                <div className="mt-1">
                  <input
                    {...register("name")}
                    type="text"
                    placeholder={t("register.companyName_placeholder")}
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="businessId"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("register_ytunnus_label")}
                </label>
                <div className="mt-1">
                  <input
                    {...register("businessId", {
                      required: true,
                      validate: isYTunnus,
                    })}
                    type="text"
                    placeholder={t("register.businessId_placeholder")}
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  {errors.businessId &&
                    errors.businessId.type === "validate" && (
                      <p className="text-sm text-red-700 mt-1">
                        {t("register_ytunnus_error")}
                      </p>
                    )}
                </div>
              </div>
            </div>
            <div>
              <div className="mt-6 grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-4">
                <div className="sm:col-span-4">
                  <label
                    htmlFor="billingInformation.postalAddress.streetAddress"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t("register_billinginfo_postal_streetAddress_label")}
                  </label>
                  <div className="mt-1">
                    <input
                      {...register(
                        "billingInformation.postalAddress.streetAddress"
                      )}
                      id="billingInformation.postalAddress.streetAddress"
                      type="text"
                      placeholder={t(
                        "register.companyStreetAddress_placeholder"
                      )}
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-6 grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-4">
                <div className="sm:col-span-2">
                  <label
                    htmlFor="billingInformation.postalAddress.postalCode"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t("register_billinginfo_postal_postalCode_label")}
                  </label>
                  <div className="mt-1">
                    <input
                      {...register(
                        "billingInformation.postalAddress.postalCode"
                      )}
                      id="billingInformation.postalAddress.postalCode"
                      type="text"
                      placeholder={t("register.companyPostalcode_placeholder")}
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="billingInformation.postalAddress.city"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t("register_billinginfo_postal_City_label")}
                  </label>
                  <div className="mt-1">
                    <input
                      {...register("billingInformation.postalAddress.city")}
                      id="billingInformation.postalAddress.city"
                      type="text"
                      placeholder={t("register.companyCity_placeholder")}
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    <input
                      hidden
                      type="text"
                      {...register("billingInformation.postalAddress.country")}
                      defaultValue="fi"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6 grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-4">
              <div className="sm:col-span-2">
                <label
                  htmlFor="billingInformationType"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("register_billinginfo_method_select_label")}
                </label>
                <select
                  {...register("billingInformationType")}
                  autoComplete="billingInformationType"
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option value="einvoicing">
                    {t("register_billinginfo_method_einvoicing_label")}
                  </option>
                  <option value="email">
                    {t("register_billinginfo_method_email_label")}
                  </option>
                  <option value="post">
                    {t("register_billinginfo_method_postal_label")}
                  </option>
                  <option value="cash">
                    {t("register_billinginfo_method_cash_label")}
                  </option>
                </select>
              </div>
            </div>
            {watchBillingInformationType === "einvoicing" && (
              <div className="mt-6 grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-4">
                <div className="sm:col-span-2">
                  <label
                    htmlFor="billingInformation.operator"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t("register_billinginfo_einvoicing_operator_label")}
                  </label>
                  <div className="mt-1">
                    <input
                      {...register("billingInformation.operator")}
                      id="billingInformation.operator"
                      type="text"
                      placeholder={t("register.companyOperator_placeholder")}
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="billingInformation.address"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t("register_billinginfo_einvoicing_address_label")}
                  </label>
                  <div className="mt-1">
                    <input
                      {...register("billingInformation.address")}
                      id="billingInformation.address"
                      name="billingInformation.address"
                      type="text"
                      placeholder={t("register.companyAddress_placeholder")}
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
              </div>
            )}
            {watchBillingInformationType === "email" && (
              <div className="mt-6 grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-4">
                <div className="sm:col-span-2">
                  <label
                    htmlFor="billingInformation.email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t("register_billinginfo_email_label")}
                  </label>
                  <div className="mt-1">
                    <input
                      {...register("billingInformation.email")}
                      id="billingInformation.email"
                      name="billingInformation.email"
                      type="text"
                      placeholder={t("register.companyEmail_placeholder")}
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
              </div>
            )}
            <div>
              <div className="sm:col-span-2 pt-4 pb-10">
                <label className="inline-flex items-center">
                  <input
                    {...register("terms")}
                    type="checkbox"
                    className="form-checkbox h-6 w-6"
                    required
                  />
                  <span className="ml-3">
                    {t("register.agree_terms_line_one")}
                    <a
                      className="underline"
                      target="_blank"
                      rel="noreferrer"
                      href={t("register.agree_terms_link")}
                    >
                      {t("register.agree_terms_link_title")}
                    </a>
                    {t("register.agree_terms_line_two")}
                  </span>
                </label>
              </div>
              <button
                type="submit"
                onClick={() => {
                  const singleValue = getValues("billingInformationType");
                  setValue("billingInformation.type", singleValue ?? "post");
                }}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-black bg-primary hover:bg-black hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {t("register_submit_button")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const SyncLoader = () => {
  const { t } = useTranslation();
  return (
    <div
      className="bg-cover bg-center min-height"
      style={{
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        backgroundImage: `url(/images/bg_taksi.jpg)`,
      }}
    >
      <div className="pt-40 sm:mx-auto sm:w-full max-w-3xl">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div className="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
            <div className="pb-10">
              <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32 ml-auto mr-auto block"></div>
            </div>
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              <span className="block">{t("register_sending_data")}</span>
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

interface SuccessProps {
  sendToEmail?: string | null;
}

const SuccessView = (props: SuccessProps) => {
  const { t } = useTranslation();
  return (
    <div
      className="bg-cover bg-center min-height"
      style={{
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        backgroundImage: `url(/images/bg_taksi.jpg)`,
      }}
    >
      <div className="sm:mx-auto sm:w-full max-w-3xl">
        <div className="pt-40 text-center text-5xl font-extrabold text-black">
          <img
            className="max-h-40 w-auto ml-auto mr-auto block"
            src={"/images/logo-taksitampere.png"}
            alt="Taksi Tampere logo"
          />
        </div>
      </div>
      <div className="pt-8 sm:mx-auto sm:w-full max-w-3xl pb-40">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div className="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              <span className="block">{t("register_success_title")}</span>
            </h2>
            <div className="mt-8 flex justify-center">
              <p>
                {t("register_success_info")}
                <span className="font-semibold">{props?.sendToEmail}</span>
                {t("register_success_info_row2")}
              </p>
            </div>
            <div className="mt-8 flex justify-center">
              <p>{t("register_success_info_row3")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface ErrorViewProps {
  status?: string | null;
  message?: string | null;
}

const ErrorView = (props?: ErrorViewProps) => {
  const { t } = useTranslation();
  return (
    <div className="rounded-md bg-red-50 p-4 mb-6">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">
            {t("register_error_title")}
          </h3>
          <div className="mt-2 text-sm text-red-700">
            <ul className="list-disc pl-5 space-y-1">
              {props?.message ?? <li>{props?.message}</li>}
              <li>
                {t("register_error_status_code")}: {props?.status}
              </li>
              <li>{t("register_error_tip")}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
