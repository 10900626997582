export enum AuthStatus {
  SIGNED_IN,
  NONE,
}

export interface ICurrent {
  authentication: AuthStatus;
  uuid: string | null;
}

export interface Navigation {
  name: string;
  to: string;
  icon: any;
  current: boolean;
}

export type BillingInformationPost = {
  type: "post";
  postalAddress: PostalAddress;
  /*streetAddress: string;
  postalCode: string;
  city: string;
  country: string;*/
};

export type PostalAddress = {
  streetAddress: string;
  postalCode: string;
  city: string;
  country: string;
};

export type BillingInformationEmail = {
  type: "email";
  email: string;
  postalAddress: PostalAddress;
};

export type BillingInformationEinvoicing = {
  type: "einvoicing";
  operator: string;
  address: string;
  postalAddress: PostalAddress;
};

export type BillingInformationCash = {
  type: "cash";
  postalAddress: PostalAddress;
};

export type BillingInformation =
  | BillingInformationPost
  | BillingInformationEmail
  | BillingInformationEinvoicing
  | BillingInformationCash;

export type ContactPerson = {
  name: string;
  phone: string;
  email: string;
};

// POST https://accounts.staging.service.taksitampere.fi/api/application
export type AccountApplicationRequest = {
  name: string;
  yTunnus: string;
  contactPerson: ContactPerson;
  billingInformation: BillingInformation;
};

// List of these is returned from GET endpoint https://accounts.staging.service.taksitampere.fi/api/application
export type AccountApplication = {
  id: string;
  name: string;
  businessId: string;
  nickname: string;
  contactPerson: ContactPerson;
  billingInformation: BillingInformation;
  createdAt: string;
  status: string;
};

export type ICabbiAccount = {
  identifier: string;
};

export type ApprovedAccount = {
  id: string;
  name: string;
  businessId: string;
  nickname: string;
  contactPerson: ContactPerson;
  billingInformation: BillingInformation;
  createdAt: string;
  status: string;
  iCabbiAccount: ICabbiAccount;
};

export type Account = {
  id: number;
  name: string;
  ref: string;
  active: string;
  type: string;
  identifier: string;
  notes: string;
  driver_notes: string;
  address: string;
  address_id: number;
  primary_contact_name: string;
  primary_contact_phone: string;
  email: string;
  priority: number;
  service_charge_type: string;
  service_charge: number;
  min_service_charge: number;
  booking_charge_type: string;
  booking_charge: number;
  min_booking_charge: number;
  discount_cost_type: string;
  discount_cost: number;
  discount_price_type: string;
  discount_price: number;
  invoice_discount: number;
  profile: string;
  pin: string;
  prepaid: number;
  site_id: number;
  account_fields: Array<AccountFields>;
  users: Array<User>;
};

export type AccountFields = {
  id: string;
  title: string;
  regex: string;
  description: string;
  type: string;
  values: string | null;
  required: string;
  active: string;
};

export type User = {
  id: string;
  name: string;
  phone: string;
  email: string | null;
  admin: number;
  web: number;
  app: number;
  account_web_type: string;
};

export type UserPoolAuth = {
  Auth?: UserPoolDetails;
};

export type UserPoolDetails = {
  userPoolId?: string;
  userPoolWebClientId?: string;
  mandatorySignIn?: boolean;
  region?: string;
};

export type Users = {
  id?: string;
  name?: string;
  phone?: string;
  email?: string;
  accountId?: string | null;
};
