import {
  CheckCircleIcon,
  XCircleIcon,
  InformationCircleIcon,
  RefreshIcon,
} from "@heroicons/react/solid";

export enum Banner {
  SUCCESS = "success",
  ERROR = "error",
  INFO = "info",
  LOADING = "loading",
}
interface IProps {
  title: string;
  type: Banner;
  actionLabel?: string;
  message?: string;
  showExtraHelp: boolean;
  messagetwo?: string;
  image?: string;
  link?: string;
  link_title?: string;
  onActionClick?: () => void;
}

const AlertBanner: React.FC<IProps> = ({
  title,
  message,
  showExtraHelp,
  messagetwo,
  image,
  link,
  link_title,
  actionLabel,
  type,
  onActionClick,
}) => {
  const renderBanner = () => {
    switch (type) {
      case Banner.SUCCESS:
        return successBanner();
      case Banner.ERROR:
        return errorBanner();
      case Banner.INFO:
        return infoBanner();
      case Banner.LOADING:
        return loadingBanner();
    }
  };

  const successBanner = () => {
    return (
      <div className="rounded-md bg-green-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <CheckCircleIcon
              className="h-5 w-5 text-green-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-green-800">{title}</h3>
            {message ? (
              <div className="mt-2 text-sm text-green-700">
                <p>{message}</p>
              </div>
            ) : (
              <div></div>
            )}
            {showExtraHelp === true && messagetwo !== "" && (
              <div className="mt-2 text-sm text-black">
                <p>{messagetwo}</p>
              </div>
            )}

            {showExtraHelp === true && link !== "" && (
              <div className="mt-2 text-sm text-black">
                <a
                  href={link}
                  className="underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  {link_title}
                </a>
              </div>
            )}

            {showExtraHelp === true && image !== "" && (
              <div className="mt-2 text-sm text-black">
                <img className="h-auto w-auto" src={image} alt="" />
              </div>
            )}
            <div className="mt-4">
              <div className="-mx-2 -my-1.5 flex">
                <button
                  type="button"
                  className="bg-green-50 px-2 py-1.5 rounded-md text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                  onClick={onActionClick}
                >
                  {actionLabel}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const errorBanner = () => {
    return (
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">{title}</h3>
            <div className="mt-2 text-sm text-red-700">
              {message ? (
                <div className="mt-2 text-sm text-red-700">
                  <p>{message}</p>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const infoBanner = () => {
    return (
      <div className="rounded-md bg-gray-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-gray-800">{title}</h3>
            <div className="mt-2 text-sm text-gray-700">
              {message ? (
                <div className="mt-2 text-sm text-gray-700">
                  <p>{message}</p>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const loadingBanner = () => {
    return (
      <div className="rounded-md bg-white-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <RefreshIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-gray-800">{title}</h3>
            <div className="mt-2 text-sm text-gray-700">
              {message ? (
                <div className="mt-2 text-sm text-gray-700">
                  <p>{message}</p>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return <div>{renderBanner()}</div>;
};

export default AlertBanner;
