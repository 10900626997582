import * as React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <div
      className="bg-cover bg-center min-height"
      style={{
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        backgroundImage: `url(/images/bg_taksi.jpg)`,
      }}
    >
      <div className="sm:mx-auto sm:w-full max-w-3xl">
        <div className="pt-40 text-center text-5xl font-extrabold text-black">
          <img
            className="max-h-40 w-auto ml-auto mr-auto block"
            src={"/images/logo-taksitampere.png"}
            alt="Taksi Tampere logo"
          />
        </div>
      </div>
      <div className="pt-8 sm:mx-auto sm:w-full max-w-3xl pb-40">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <h1 className="pt-5 text-center text-3xl font-extrabold text-black pb-5">
            {t("notfound.title")}
          </h1>
          <p className="text-center pb-8">
            {t("notfound.paragraph")}
            <Link className="underline" to="/">
              {t("notfound.link_title")}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
